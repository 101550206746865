import React from 'react';
import { useSignupURL } from '../utils';
import Button from './Button';

import styles from './TryNowForm.module.css';

export default function TryNowForm() {
  const signupURL = useSignupURL();

  return (
    <div className={styles.tryNow}>
      <Button href={signupURL} size="large" variant="attention">
        Try now
      </Button>
      <p className={styles.info}>Free 14-day trial. No credit card required.</p>
    </div>
  );
}
