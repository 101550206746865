import { useEffect, useState } from 'react';

export function useSignupURL(): string {
  const [promoCode, setPromoCode] = useState<string | null>(null);

  useEffect(() => {
    setPromoCode(
      typeof window !== 'undefined'
        ? window.localStorage.getItem('promoCode')
        : null,
    );
  }, []);

  return `https://my.wealthie.app/signup${
    promoCode
      ? `?${new URLSearchParams({
          promoCode,
        }).toString()}`
      : ''
  }`;
}

export default {};
