import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import favicon from '../assets/favicon.png';
import '../assets/global.css';
import styles from './Layout.module.css';
import mixins from '../mixins.module.css';
import TryNowForm from './TryNowForm';
import Header from './Header';

interface LayoutProps {
  children: React.ReactNode;
  variant?: 'feature' | 'regular';
}

export default function Layout({ children, variant = 'regular' }: LayoutProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const { top } = ref.current.getBoundingClientRect();

    // 0 is too little for Chrome on Android when going to an anchor
    if (top <= 1) {
      document.body.classList.add('showHeader');
    } else {
      document.body.classList.remove('showHeader');
    }

    if (top < -window.innerHeight / 16) {
      document.body.classList.add('smallHeader');
    } else {
      document.body.classList.remove('smallHeader');
    }
  }, [ref]);

  useEffect(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll, true);

    return () => document.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll]);

  return (
    <React.StrictMode>
      <div className={styles.wrapper}>
        <Helmet>
          <link rel="shortcut icon" type="image/png" href={favicon} />
        </Helmet>

        <main
          className={classNames(styles.main, styles[`${variant}Variant`])}
          ref={ref}
        >
          <Header sticky variant={variant} />
          {children}
        </main>

        <div className={styles.bottomWrapper}>
          <section className={classNames(styles.cta, mixins.content)}>
            <h2>Ready to give&nbsp;it&nbsp;a&nbsp;go?</h2>
            <TryNowForm />
          </section>
          <footer className={styles.footer}>
            <div className={mixins.content}>
              <p>© {new Date().getFullYear()} Wealthie. All rights reserved.</p>
              <ul>
                <li>
                  <Link to="/privacy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms of service</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </React.StrictMode>
  );
}
