import React from 'react';
import styles from './Loader.module.css';

export default function Loader() {
  return (
    <div className={styles.loader}>
      Loading...
      <div className={styles.one} />
      <div className={styles.two} />
      <div className={styles.three} />
    </div>
  );
}
