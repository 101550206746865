import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { ReactComponent as LogoImage } from '../assets/logo.svg';

import styles from './Header.module.css';
import mixins from '../mixins.module.css';
import Button from './Button';
import { useSignupURL } from '../utils';

interface HeaderProps {
  logIn?: boolean;
  sticky?: boolean;
  variant?: 'feature' | 'hero' | 'regular';
}

export default function Header({
  logIn,
  sticky,
  variant = 'regular',
}: HeaderProps) {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const signupURL = useSignupURL();

  return (
    <div
      className={classNames(styles.headerWrapper, styles[`${variant}Variant`], {
        [styles.mobileNav]: isMobileNavOpen,
        [styles.sticky]: sticky,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <header
        onClick={() => {
          setIsMobileNavOpen(false);
          document.body.classList.remove('noScroll');
        }}
      >
        <div className={mixins.content}>
          <Link className={styles.logo} to="/">
            <LogoImage aria-hidden="true" />
            <h1>Wealthie</h1>
          </Link>

          <button
            className={classNames(styles.navButton, {
              [styles.isActive]: isMobileNavOpen,
            })}
            type="button"
            onClick={(e) => {
              e.stopPropagation();

              if (isMobileNavOpen) {
                setIsMobileNavOpen(false);
                document.body.classList.remove('noScroll');
              } else {
                setIsMobileNavOpen(true);
                document.body.classList.add('noScroll');
              }
            }}
            aria-label={isMobileNavOpen ? 'Close menu' : 'Open menu'}
            aria-controls="navigation"
          >
            <span className={styles.inner} />
          </button>

          <nav>
            <ul>
              <li>
                <Link to="/#features">Features</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                {logIn ? (
                  <Button href="https://my.wealthie.app/login">Log in</Button>
                ) : (
                  <Button href={signupURL} variant="attention">
                    Sign up
                  </Button>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}
