import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import styles from './Button.module.css';
import Loader from './Loader';

function noop() {
  // noop
}

interface BaseProps {
  children: React.ReactNode;
  isLoading?: boolean;
  size?: 'medium' | 'large';
  variant?: 'attention' | 'primary' | 'regular';
}

interface ButtonProps extends BaseProps {
  href?: never;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
}

interface LinkProps extends BaseProps {
  href: string;
  onClick?: never;
  type?: never;
}

export default function Button({
  children,
  href,
  isLoading = false,
  onClick = noop,
  size = 'medium',
  type = 'button',
  variant = 'regular',
}: ButtonProps | LinkProps) {
  const classes = classNames(
    styles.button,
    styles[`${variant}Variant`],
    styles[`${size}Size`],
  );
  const content = (
    <>
      <div className={classNames({ [styles.hiddenContent]: isLoading })}>
        {children}
      </div>
      {isLoading && <Loader />}
    </>
  );

  return href ? (
    <Link className={classes} to={href}>
      {content}
    </Link>
  ) : (
    <button
      className={classes}
      disabled={isLoading}
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {content}
    </button>
  );
}
